import React from 'react';
import { Stack, Typography, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { USER_SUBSCRIPTIONS, PREMIUM_FEATURES, FREE_FEATURES } from 'constants/userSubscriptionsTypes';

// ----------------------------------------------------------------------

export const PREMIUM_FULL_FEATURES = [...PREMIUM_FEATURES, ...FREE_FEATURES];

export default function PremiumSubscriptionQuickDetails() {
  return (
    <>
      <Typography variant="h3" color="secondary">
        {USER_SUBSCRIPTIONS.premium.title}
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body2" color="primary">
          <strong>30 giorni di prova</strong> gratuita, poi
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', my: 1 }}>
          <Typography variant="h3" sx={{ color: 'text.secondary' }}>
            €
          </Typography>
          <Typography variant="h2" sx={{ mx: 1 }}>
            {USER_SUBSCRIPTIONS.premium.price}{' '}
            <Typography component="span" variant="body1">
              + iva
            </Typography>
          </Typography>

          <Typography
            gutterBottom
            component="span"
            variant="subtitle1"
            sx={{
              alignSelf: 'flex-end',
              color: 'text.secondary',
            }}
          >
            /mese
          </Typography>
        </Box>
      </Box>
      <Stack direction="column" spacing={2} sx={{ mb: 4 }}>
        <Stack direction="row" alignItems="flex-start" spacing={1}>
          <AddIcon color="primary" />
          <Typography variant="body1">Tutte le funzionalità del piano Basic</Typography>
        </Stack>
        {PREMIUM_FEATURES.map((feature, i) => (
          <Stack key={i} direction="row" alignItems="flex-start" spacing={1}>
            <CheckCircleIcon color="primary" />
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: feature.replace(
                  'massimo 50 appuntamenti al mese',
                  '<strong>massimo 50 appuntamenti al mese</strong>',
                ),
              }}
            />
          </Stack>
        ))}
      </Stack>
    </>
  );
}

PremiumSubscriptionQuickDetails.propTypes = {};
