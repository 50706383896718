// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from '@sentry/react';
import { QueryClientProvider, QueryClient } from 'react-query';
import numeral from 'numeral';

import { it } from 'date-fns/locale/it';
import 'moment/locale/it';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// theme
import ThemeProvider from './theme';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from './contexts/FirebaseContext';
import { FlagsProvider } from './contexts/FlagsContext';
import { RemoteConfigProvider } from './contexts/RemoteConfigContext';

//
import App from './App';
import Page500 from './pages/Page500';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import analytics from './utils/analytics';
import logger from './utils/logger';
import { setMomentConfig } from './utils/moment';

// ----------------------------------------------------------------------

try {
  if (numeral.locales.it === undefined) {
    numeral.register('locale', 'it', {
      delimiters: {
        thousands: ' ',
        decimal: ',',
      },
      currency: {
        symbol: '€',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      ordinal(number) {
        return number === 1 ? 'er' : 'ème';
      },
    });
  }
} catch (e) {
  console.log(e);
}

setMomentConfig();
numeral.locale('it');

analytics.init();
logger.init();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(<ThemeProvider>
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <RemoteConfigProvider>
        <FlagsProvider>
          <HelmetProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
              <SettingsProvider>
                <CollapseDrawerProvider>
                  <BrowserRouter>
                    <Sentry.ErrorBoundary
                      fallback={<Page500 onClick={() => window.location.reload()} buttonLabel="Ricarica" />}
                    >
                      <App />
                    </Sentry.ErrorBoundary>
                  </BrowserRouter>
                </CollapseDrawerProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </HelmetProvider>
        </FlagsProvider>
      </RemoteConfigProvider>
    </AuthProvider>
  </QueryClientProvider>
</ThemeProvider>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
