import PlanUnlimitedIllustration from 'assets/illustration_plan_unlimited';
import PlanPremiumIllustration from 'assets/illustration_plan_premium';

export const userSubscriptionsTypes = {
  FREE: 'free',
  PREMIUM: 'premium',
  UNLIMITED: 'unlimited',
};

const USER_SUBSCRIPTIONS = {
  [userSubscriptionsTypes.FREE]: { id: userSubscriptionsTypes.FREE, title: 'Free', price: 0, isFree: true },
  [userSubscriptionsTypes.PREMIUM]: {
    id: userSubscriptionsTypes.PREMIUM,
    icon: '👑',
    title: '👑 Premium',
    plainTextTitle: 'Premium',
    illustration: <PlanPremiumIllustration />,
    price: 18,
    isFree: false,
    limits: {
      eventsPerMonth: 50,
    },
  },
  [userSubscriptionsTypes.UNLIMITED]: {
    id: userSubscriptionsTypes.UNLIMITED,
    icon: '♾️',
    title: '♾️ Unlimited',
    plainTextTitle: 'Unlimited',
    illustration: <PlanUnlimitedIllustration />,
    price: 39,
    isFree: false,
  },
};

export const FREE_FEATURES = [
  'Dati anagrafici dei pazienti',
  'Schede anamnestiche',
  'Gestione trattamenti',
  'Gestione documenti del paziente',
  'Dashboard con statistiche base',
];

export const PREMIUM_FEATURES = [
  'Calendario: massimo 50 appuntamenti al mese',
  'Messaggi automatici di conferma e promemoria appuntamento',
  'Tieni traccia di incassi e metodi di pagamento',
  'Report su appuntamenti e incassi nella dashboard',
  'Invio richiesta di recensione su WhatsApp',
];

export const UNLIMITED_FEATURES = ['Appuntamenti illimitati'];

export { USER_SUBSCRIPTIONS };
