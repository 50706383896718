import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { generatePath, useNavigate } from 'react-router-dom';

import { PATH_APPLICATION } from 'routes/paths';
import logger from 'utils/logger';
import { USER_SUBSCRIPTIONS, userSubscriptionsTypes } from 'constants/userSubscriptionsTypes';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useAuth } from 'contexts/FirebaseContext';
import usePurchaseSubscriptionMutation from '../hooks/usePurchaseSubscriptionMutation';

export default function PurchaseSubscriptionButton({ subscriptionType, fullWidth }) {
  const { user } = useAuth();
  const purchaseSubscriptionMutation = usePurchaseSubscriptionMutation();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false);
  const navigate = useNavigate();

  const hasSubscriptionActive = Boolean(user.integrations?.stripe?.subscriptionId);
  const isStripeCustomer = Boolean(user.integrations?.stripe?.customerId);

  const currentSubscription = USER_SUBSCRIPTIONS[user.subscription?.type] || USER_SUBSCRIPTIONS.free;

  const targetSubscription = USER_SUBSCRIPTIONS[subscriptionType];

  if (currentSubscription.id === USER_SUBSCRIPTIONS[subscriptionType].id) {
    return null;
  }

  const handlePurchaseSubscription = async () => {
    try {
      const checkoutSession = await purchaseSubscriptionMutation.mutateAsync({
        subscriptionType,
      });

      if (checkoutSession && checkoutSession.paymentLink) {
        window.location.href = checkoutSession.paymentLink;
      }

      if (checkoutSession && checkoutSession.subscriptionUpdated) {
        navigate(generatePath(PATH_APPLICATION.user.subscription.thankYou, { subscriptionType }));
      }
    } catch (error) {
      enqueueSnackbar('Si è verificato un errore! Riprova.', {
        variant: 'error',
      });
      logger.captureException(error);
    }
  };

  if (hasSubscriptionActive) {
    return (
      <Box>
        <LoadingButton
          variant="contained"
          size="large"
          fullWidth={fullWidth}
          loading={purchaseSubscriptionMutation.isLoading}
          onClick={() => setIsConfirmationDialogOpen(true)}
        >
          PASSA AL PIANO {targetSubscription.plainTextTitle.toUpperCase()}
        </LoadingButton>
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title={`Sei sicuro di voler passare al piano ${targetSubscription.plainTextTitle}?`}
          description={
            <>
              <Typography variant="body1">
                L'aggiornamento del piano è immediato. Il costo della tua sottoscrizione verrà calcolato in modo
                proporzionale per il mese corrente. Eventuali costi ti verranno addebitato subito.
              </Typography>
            </>
          }
          confirmLabel={`Sì, passa a ${targetSubscription.plainTextTitle}`}
          discardLabel="Annulla"
          onConfirm={handlePurchaseSubscription}
          isSubmitting={purchaseSubscriptionMutation.isLoading}
          onDiscard={() => setIsConfirmationDialogOpen(false)}
          onClose={() => setIsConfirmationDialogOpen(false)}
        />
      </Box>
    );
  }

  return (
    <Box>
      <LoadingButton
        variant="contained"
        size="large"
        fullWidth={fullWidth}
        loading={purchaseSubscriptionMutation.isLoading}
        onClick={handlePurchaseSubscription}
      >
        {/* Since this point is reached only when hasSubscriptionActive is false, 
          isStripeCustomer true means that the user has been a customer in the past 
          and therefore its not possible to get the trial period anymore */}
        {isStripeCustomer || subscriptionType === userSubscriptionsTypes.UNLIMITED ? 'ATTIVA ORA' : 'PROVA GRATIS'}
      </LoadingButton>
    </Box>
  );
}

PurchaseSubscriptionButton.propTypes = {
  subscriptionType: PropTypes.oneOf(Object.values(userSubscriptionsTypes)).isRequired,
  fullWidth: PropTypes.bool,
};
