import { Box, Typography, LinearProgress, Stack, Link, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import WarningIcon from '@mui/icons-material/Warning';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import { Link as RRDLink, generatePath } from 'react-router-dom';

import { USER_SUBSCRIPTIONS } from 'constants/userSubscriptionsTypes';

import { useAuth } from 'contexts/FirebaseContext';
import { PATH_APPLICATION } from 'routes/paths';
import useUserUsage from '../../hooks/useUserUsage';

// NOTE: This component can be imprpved to handle multiple subscription limits

const SubscriptionStatusWidget = ({ showUsage, isCollapse }) => {
  const { user } = useAuth();
  const userUsage = useUserUsage({ enabled: showUsage });
  const currentSubscription = USER_SUBSCRIPTIONS[user.subscription?.type] ?? USER_SUBSCRIPTIONS.free;

  const hasReachedLimit = userUsage?.eventsPerMonth?.remaining === 0;

  if (isCollapse) {
    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box sx={{ width: '100%', textAlign: 'center' }}>{currentSubscription.icon}</Box>
      </Stack>
    );
  }

  return (
    <Box>
      <Box sx={{ pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1">Piano {currentSubscription.title}</Typography>
          <IconButton
            size="small"
            LinkComponent={RRDLink}
            to={generatePath(PATH_APPLICATION.user.settings, { tab: 'subscription' })}
          >
            <SettingsIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={1}>
          {hasReachedLimit && <WarningIcon fontSize="small" color="warning" />}
          <Typography variant="body2" fontStyle="italic">
            Attivo
          </Typography>
        </Stack>
      </Box>

      {showUsage && userUsage && userUsage.eventsPerMonth && currentSubscription.limits && (
        <Box>
          <Box sx={{ textAlign: 'right' }}>
            <Typography variant="caption">
              {userUsage.eventsPerMonth.usage} / {userUsage.eventsPerMonth.limit}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={userUsage.eventsPerMonth.usagePercentage}
            color={hasReachedLimit ? 'error' : 'primary'}
          />

          <Box sx={{ mt: 0.5 }}>
            {hasReachedLimit ? (
              <Stack alignItems="center" direction="row" spacing={1}>
                <Typography variant="caption">
                  Hai raggiunto il limite di appuntamenti per questo mese.{' '}
                  <Link
                    component={RRDLink}
                    to={generatePath(PATH_APPLICATION.user.settings, { tab: 'subscription' })}
                    sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  >
                    Fai l'upgrade al piano Unlimited
                  </Link>{' '}
                  per continuare a programmare nuovi appuntamenti
                </Typography>
              </Stack>
            ) : (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="caption">Appuntamenti creati questo mese</Typography>
                <Tooltip title="Con il piano Premium hai a disposizione 50 appuntamenti al mese. Qui puoi vedere quanti ne hai creati finora. Il conteggio si resetterà automaticamente il primo giorno di ogni mese solare.">
                  <IconButton size="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

SubscriptionStatusWidget.propTypes = {
  showUsage: PropTypes.bool,
  isCollapse: PropTypes.bool,
};

export default SubscriptionStatusWidget;
